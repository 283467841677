import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CultService from '../../Services/CultService';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import toast from 'react-hot-toast';
import PageHeading from '../../Components/PageHeading';
import PageLoader from '../../Components/PageLoader';
import {
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import MentionProgress from './Components/MentionProgress';
import { DotIcon } from '../../Components/Icons/Icons';
import { extractNumberFromString } from '../../Utils/helper/commonUtils';
import { AppColors } from '../../Utils/helper/AppColors';

const COLORS = {
	Neutral: AppColors.STATUS_YELLOW,
	Positive: AppColors.STATUS_GREEN,
	Negative: AppColors.STATUS_RED,
};

export default function MoodInsights() {
	const [loading, setLoading] = useState(false);
	const company = useSelector((state) => state?.app?.company);
	const [details, setDetails] = useState(null);

	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const getDetails = async () => {
		setLoading(true);
		try {
			let res = await CultService.getActionalbleInsigts({ params: { companyId: company?._id } });
			setDetails(res?.data?.data);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const groupAndSortMentions = (details) => {
		return details
			?.flatMap((topic) => topic?.subTopic)
			?.reduce((acc, subtopic) => {
				const { sentiment, mentions } = subtopic;

				if (!acc[sentiment]) {
					acc[sentiment] = [];
				}
				acc[sentiment].push(...mentions);
				acc[sentiment].sort((a, b) => extractNumberFromString(b?.reported || 0) - extractNumberFromString(a?.reported || 0));
				return acc;
			}, {});
	};

	const groupedMentions = groupAndSortMentions(details);

	useEffect(() => {
		if (company) getDetails();
	}, [company]);

	return (
		<Stack gap={3}>
			{loading && <PageLoader />}
			<PageHeading title="Mood" subTitle="Insights into profound employee dynamics" />

			<Stack gap={2}>
				<ToggleButtonGroup size="small" color="primary" value={value} exclusive onChange={handleChange}>
					{Object.keys(groupedMentions || {}).map((i, index) => (
						<ToggleButton sx={{ textTransform: 'none', px: 2, borderRadius: '8px', gap: 1, alignItems: 'center' }} value={index}>
							<DotIcon color={COLORS[i]} />
							<Typography display="flex" alignItems="center" gap={1} variant="subtitle2" fontSize={13} fontWeight={500}>
								{i}
							</Typography>
						</ToggleButton>
					))}
				</ToggleButtonGroup>

				{Object?.keys(groupedMentions || {})?.map(
					(mention, index) =>
						index == value && (
							<TableContainer sx={{ maxHeight: 'calc(100vh - 168px)' }} key={index}>
								<Table>
									<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
										<TableCell>Insights</TableCell>
										<TableCell>Mentions</TableCell>
										<TableCell>Recommended Action</TableCell>
									</TableHead>
									<TableBody>
										{groupedMentions[mention]?.map((item, index1) => {
											return (
												<TableRow key={index1}>
													<TableCell>
														<Typography variant="subtitle2">{item?.text}</Typography>
													</TableCell>
													<TableCell>
														<MentionProgress value={Math.round(item?.reported?.replace('%', ''))} hidePercentageSign />
													</TableCell>

													<TableCell>
														<Typography variant="subtitle2">{item?.solution}</Typography>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						),
				)}
			</Stack>
		</Stack>
	);
}

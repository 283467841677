import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React from 'react';
import { AppColors } from '../../../Utils/helper/AppColors';
import MentionProgress from '../../Reporting/Components/MentionProgress';
import { FiChevronDown } from 'react-icons/fi';

export default function CampaignDetailsInsights({ insights }) {
	return insights.map((insight, index) => (
		<Accordion key={index}>
			<AccordionSummary expandIcon={<FiChevronDown color={AppColors.DARK_GREY} size={20} />}>
				<Stack flex={1} mr={8} direction="row" justifyContent="space-between" alignItems="center">
					<Typography variant="subtitle1">{insight.name}</Typography>
					<Typography variant="subtitle2">
						Reported by <span style={{ fontWeight: 600 }}>{insight?.reportedBy || 0}</span>
					</Typography>
				</Stack>
			</AccordionSummary>
			<AccordionDetails>
				<Stack gap={2}>
					{insight?.subTopic?.map((sub) => (
						<TableContainer>
							<Table>
								<TableHead>
									<TableCell colSpan={3}>
										<Typography variant="subtitle1">{sub?.name}</Typography>
									</TableCell>
								</TableHead>
								<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
									<TableCell>Insights</TableCell>
									<TableCell>Recommended Action</TableCell>
									<TableCell>Mentions</TableCell>
								</TableHead>

								<TableBody>
									{sub?.mentions?.map((mention) => (
										<TableRow>
											<TableCell>
												<Typography variant="subtitle2">{mention?.text}</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="subtitle2">{mention?.solution}</Typography>
											</TableCell>
											<TableCell>
												<MentionProgress value={parseInt(mention?.reported?.replace('%', ''))} />
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					))}
				</Stack>
			</AccordionDetails>
		</Accordion>
	));
}

import { AppColors } from './AppColors';

export const SIGN_UP_FLOW = false;

export const DASHBOARD_VIEWS = {
	ORGANIZATIONAL: { CODE: 'ORGANIZATIONAL', LABEL: 'Organizational', COLOR: '#3a39f6' },
	DEPARTMENTAL: { CODE: 'DEPARTMENTAL', LABEL: 'Departmental', COLOR: '#3a39f6' },
	HIERARCHICAL: { CODE: 'HIERARCHICAL', LABEL: 'Hierarchical', COLOR: '#3a39f6' },
	GENDER: { CODE: 'GENDER', LABEL: 'Gender', COLOR: '#7F56D9' },
	LOCATION: { CODE: 'LOCATION', LABEL: 'Location', COLOR: '#7F56D9' },
};

export const DEPARTMENTS = [
	{ name: 'Design', code: 'DESIGN' },
	{ name: 'Product', code: 'PRODUCT' },
	{ name: 'Marketing', code: 'MARKETING' },
	{ name: 'Software Engineering', code: 'SOFTWARE' },
	{ name: 'Finance', code: 'FINANCE' },
	{ name: 'Operations', code: 'OPERATIONS' },
	{ name: 'Customer Success', code: 'CUSTOMER_SUCCESS' },
];

export const GENDERS = [
	{ name: 'Male', code: 'male', color: 'purple' },
	{ name: 'Female', code: 'female', color: 'orange' },
	{ name: 'Others', code: 'other', color: 'blue' },
];

export const CAMPAIGN_STATUS = {
	live: { code: 'live', color: AppColors.STATUS_YELLOW },
	stopped: { code: 'stopped', color: AppColors.STATUS_RED },
	completed: { code: 'completed', color: AppColors.STATUS_GREEN },
	draft: { code: 'draft', color: AppColors.STATUS_GREY },
};

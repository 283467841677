import {
	Button,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import DragDropUpload from '../../Components/DragDropUpload';
import { AppColors } from '../../Utils/helper/AppColors';
import { Tag } from 'antd';
import { AddIcon, DeleteIcon, EditIcon } from '../../Components/Icons/Icons';
import { useNavigate } from 'react-router-dom';
import { PATH_NAME } from '../../Routes/Pathname';
import CultService from '../../Services/CultService';
import DeleteConfirm from '../../Components/DeleteConfirm';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import PageLoader from '../../Components/PageLoader';
import DepartmentTag from '../../Components/DepartmentTag';
import SearchBar from '../../Components/SearchBar';
import { filterBySearch } from '../../Utils/helper/commonUtils';
import { useSelector } from 'react-redux';
import UserThumbnail from '../../Components/User/UserThumbnail';

export default function EmployeesList() {
	const [list, setList] = useState([]);
	const navigate = useNavigate();
	const [deleteModal, setDeleteModal] = useState(null);
	const [deleting, setDeleting] = useState(false);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const fileRef = useRef();

	const company = useSelector((state) => state?.app?.company);

	const getList = async () => {
		setLoading(true);
		try {
			let res = await Promise.race([CultService.getEmployeesList({ params: { companyId: company?._id } })]);
			if (res.status) {
				setList(res.data);
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = async () => {
		setDeleting(true);
		try {
			let res = await CultService.deleteUser({ params: { id: deleteModal } });
			if (res?.status == 200) {
				setDeleteModal(false);
				getList();
			}
		} catch (err) {
		} finally {
			setDeleting(false);
		}
	};

	useEffect(() => {
		if (company) getList();
	}, [company]);

	const handleImport = async (e) => {
		const formData = new FormData();
		formData.append('file', e.target.files[0]);
		formData.append('companyId', company?._id);
		const res = await CultService.importUserExcel({
			data: formData,
			config: {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		});
		if (res.status == 200) {
			getList();
		}
	};

	return (
		<Stack gap={3}>
			<PageHeading title="Employees" />

			<DragDropUpload
				fileRef={fileRef}
				subText="CSV, XSLS or XLS (max. 50 mb)"
				sampleFile="sample_user.xlsx"
				onUpload={handleImport}
				extra="While entering the details in excel file, please ensure that the phone numbers are added with the clountry code and time zone to be picked from the list of time zone mentioned in the excel sheet."
			/>

			<TableContainer sx={{ height: 'calc(100vh - 250px)' }}>
				<Table stickyHeader>
					<TableHead>
						<TableCell colSpan={6}>
							<Stack direction="row" alignItems="flex-start" justifyContent="space-between">
								<Stack>
									<Stack direction="row" alignItems="center" gap={1}>
										<Typography variant="body1">Team Members</Typography>
										<Tag style={{ borderRadius: 50 }} color="purple">
											{list?.length} Users
										</Tag>
									</Stack>
									<Typography variant="subtitle2">Manage your team members and their account permissions here.</Typography>
								</Stack>
								<Stack direction="row" gap={2} alignItems="center">
									<SearchBar search={search} setSearch={setSearch} />
									<Button
										onClick={() => navigate(PATH_NAME.EMPLOYEE_ADD)}
										startIcon={<AddIcon color={AppColors.WHITE} />}
										variant="contained"
									>
										Add Employee
									</Button>
								</Stack>
							</Stack>
						</TableCell>
					</TableHead>
					<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
						<TableCell>Name</TableCell>
						<TableCell>Email address</TableCell>
						<TableCell>Phone No.</TableCell>
						<TableCell>Team</TableCell>
						<TableCell>Timezone</TableCell>
						<TableCell />
					</TableHead>

					<TableBody>
						{filterBySearch(list, search, ['email', 'phoneNumber', 'department'])?.map((c, index) => (
							<TableRow key={index}>
								<TableCell>
									<UserThumbnail userId={c?._id} user={c} />
								</TableCell>

								<TableCell>
									<Typography variant="subtitle2">{c?.email}</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2">{c?.phoneNumber}</Typography>
								</TableCell>
								<TableCell>
									<Stack direction="row" gap={1} flexWrap="wrap">
										{c?.department && <DepartmentTag department={c?.department} />}
									</Stack>
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2">{c?.timezone}</Typography>
								</TableCell>
								<TableCell>
									<Stack direction="row" alignItems="center" gap={1}>
										<IconButton onClick={() => setDeleteModal(c._id)} size="small">
											<DeleteIcon />
										</IconButton>
										<IconButton onClick={() => navigate(PATH_NAME.EMPLOYEE_EDIT, { state: { user: c } })} size="small">
											<EditIcon />
										</IconButton>
									</Stack>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* <CustomTablePagination /> */}

			<DeleteConfirm
				open={Boolean(deleteModal)}
				onClose={() => setDeleteModal(false)}
				onDelete={handleDelete}
				loading={deleting}
				title="Delete User?"
				subTitle="Are you sure you want to delete this user?"
			/>

			{loading && <PageLoader />}
		</Stack>
	);
}

import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import EmployeeDetails from './Components/EmployeeDetails';
import { useNavigate, useParams } from 'react-router-dom';
import CultService from '../../Services/CultService';
import EmpoyeeConversations from './Components/EmpoyeeConversations';

export default function EmployeeDetailsContainer() {
	const { employeeId } = useParams();
	const navigate = useNavigate();
	const [details, setDetails] = useState({});

	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		if (newValue !== null) {
			setValue(newValue);
		}
	};

	const getDetails = async () => {
		let res = await CultService.getUserById({ params: { id: employeeId } });
		if (res.status == 200) {
			setDetails(res?.data?.data);
		}
	};

	const tabs = [
		{
			code: 'Details',
			Component: EmployeeDetails,
			props: { details },
		},
		{
			code: 'Conversations',
			Component: EmpoyeeConversations,
		},
	];

	useEffect(() => {
		if (employeeId) {
			getDetails();
		}
	}, [employeeId]);

	return (
		<Stack>
			<PageHeading title="Employee" />

			<Stack mt={4} gap={2}>
				<ToggleButtonGroup size="small" color="primary" value={value} exclusive onChange={handleChange}>
					{tabs?.map((tab, index) => (
						<ToggleButton
							key={index}
							sx={{ textTransform: 'none', px: 2, borderRadius: '8px', alignItems: 'center' }}
							value={index}
						>
							<Typography variant="subtitle2" fontSize={13} fontWeight={500}>
								{tab?.code}
							</Typography>
						</ToggleButton>
					))}
				</ToggleButtonGroup>

				{tabs.map(({ Component, props }, index) => index == value && <Component {...props} />)}
			</Stack>
		</Stack>
	);
}

import {
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import CultService from '../../Services/CultService';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import PageLoader from '../../Components/PageLoader';
import { DotIcon } from '../../Components/Icons/Icons';
import { extractNumberFromString } from '../../Utils/helper/commonUtils';

export default function CriticalRisks() {
	const [loading, setLoading] = useState(false);

	const company = useSelector((state) => state?.app?.company);
	const [details, setDetails] = useState(null);

	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const getScoreColor = (score) => {
		let res = '#000';
		if (score < 30) {
			res = '#2B974F';
		}
		if (score >= 30 && score <= 65) {
			res = '#FFA11D';
		}
		if (score > 65) {
			res = '#FF2828';
		}
		return res;
	};

	const getDetails = async () => {
		setLoading(true);
		try {
			let res = await CultService.getActionalbleInsigts({ params: { companyId: company?._id } });
			setDetails(res?.data?.data);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (company) getDetails();
	}, [company]);

	const list = details?.reduce((acc, item) => {
		const { name, subTopic } = item;
		const negativeMentions = subTopic
			.filter((subtopic) => subtopic.sentiment === 'Positive')
			.flatMap((subtopic) => subtopic.mentions?.filter((j) => extractNumberFromString(j?.reported)));
		if (negativeMentions.length > 0) {
			negativeMentions.sort((a, b) => extractNumberFromString(b.reported) - extractNumberFromString(a.reported));
			acc[name] = (acc[name] || []).concat(negativeMentions);
		}
		return acc;
	}, {});

	return (
		<Stack>
			{loading && <PageLoader />}
			<PageHeading title="Critical Risks" />

			<Stack mt={5} gap={2}>
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<ToggleButtonGroup size="small" color="primary" value={value} exclusive onChange={handleChange}>
						{Object.keys(list || {}).map((i, index) => (
							<ToggleButton
								sx={{ textTransform: 'none', px: 2, borderRadius: '8px', gap: 1, alignItems: 'center' }}
								value={index}
							>
								<Typography display="flex" alignItems="center" gap={1} variant="subtitle2" fontSize={13} fontWeight={500}>
									{i}
								</Typography>
							</ToggleButton>
						))}
					</ToggleButtonGroup>
					<Stack direction="row" alignItems="center" gap={1}>
						<Stack direction="row" alignItems="center" gap={1}>
							<DotIcon size={16} color={getScoreColor(20)} />
							<Typography variant="subtitle2" fontSize={12}>
								High
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="center" gap={1}>
							<DotIcon size={16} color={getScoreColor(50)} />
							<Typography variant="subtitle2" fontSize={12}>
								Moderate
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="center" gap={1}>
							<DotIcon size={16} color={getScoreColor(80)} />
							<Typography variant="subtitle2" fontSize={12}>
								Low
							</Typography>
						</Stack>
					</Stack>
				</Stack>

				{Object?.keys(list || {})?.map(
					(mention, index) =>
						index == value && (
							<TableContainer sx={{ maxHeight: 'calc(100vh - 168px)' }} key={index}>
								<Table>
									<TableHead>
										<TableCell />
										<TableCell>Risk</TableCell>
										<TableCell>Result</TableCell>
									</TableHead>
									<TableBody>
										{list[mention]?.map((item, index1) => {
											return (
												<TableRow key={index1}>
													<TableCell>
														<Typography variant="subtitle2">
															<span style={{ fontWeight: 600 }}>{item?.reported}</span> {item?.text}
														</Typography>
													</TableCell>
													<TableCell>
														<DotIcon size={18} color={getScoreColor(parseInt(item?.reported?.replace('%', '')))} />
													</TableCell>
													<TableCell>
														<Typography variant="subtitle2">{item?.impact}</Typography>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						),
				)}
			</Stack>
		</Stack>
	);
}

import { Avatar, Divider, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import { AppColors } from '../../Utils/helper/AppColors';
import DragDropUpload from '../../Components/DragDropUpload';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import CultService from '../../Services/CultService';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import { DeleteIcon } from '../../Components/Icons/Icons';
import IconButton from '../../Components/IconButton';

export default function Settings() {
	const company = useSelector((state) => state?.app?.company);
	const [details, setDetails] = useState({});
	const [loading, setLoading] = useState(false);

	const handleUpdate = async () => {
		setLoading(true);
		const payload = {
			name: details?.name,
			facebookLink: details?.facebookLink,
			instagramLink: details?.instagramLink,
			youtubeLink: details?.youtubeLink,
			xLink: details?.xLink,
			logo: details?.logo,
			linkedinLink: details?.linkedinLink,
		};
		try {
			let res = await CultService.updateCompany({ params: { id: company?._id }, data: payload });
			if (res.status == 200) {
				toast.success(HelperTexts.UPDATED_SUCCESSFULLY);
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const uploadImage = async (e) => {
		const image = e.target.files[0];
		const data = new FormData();
		data.append('file', image);
		let res = await CultService.updateCompany({ params: { id: company?._id }, data });
		if (res.status == 200) {
			setDetails({ ...company, logo: res.data?.data?.logo });
			toast.success(HelperTexts.UPDATED_SUCCESSFULLY);
		}
	};

	useEffect(() => {
		if (company) {
			setDetails({ ...company });
		}
	}, [company]);

	const handleChange = (e) => {
		setDetails({ ...details, [e.target.name]: e.target.value });
	};

	return (
		<Stack minHeight="100%" sx={{ '& .MuiDivider-root': { my: 2 } }}>
			<PageHeading title="Settings" subTitle="Manage your company settings" />

			<Stack mt={4}>
				<Typography variant="body1" fontSize={20}>
					Company info
				</Typography>
			</Stack>

			<Divider />

			<Grid container>
				<Grid item xs={4}>
					<InputLabel>Company Logo</InputLabel>
					<InputLabel sx={{ color: AppColors.TEXT_TERTIARY, fontWeight: 400 }}>This will be the logo of the company.</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<Stack direction="row" alignItems="flex-start" gap={2}>
						<Stack position="relative">
							{details?.logo && (
								<IconButton
									onClick={() => setDetails({ ...details, logo: '' })}
									rounded
									color={AppColors.TEXT_RED}
									size={22}
									icon={<DeleteIcon color={AppColors.WHITE} size={14} />}
									sx={{ position: 'absolute', right: 0, bottom: 0, zIndex: 1 }}
								/>
							)}
							<Avatar src={details?.logo} sx={{ height: 60, width: 60 }} />
						</Stack>
						<DragDropUpload
							onUpload={uploadImage}
							borderColor={AppColors.PRIMARY_LIGHT}
							subText="SVG, PNG, JPG or GIF (max. 800x400px)"
						/>
					</Stack>
				</Grid>
			</Grid>

			<Divider />
			<Grid container>
				<Grid item xs={4}>
					<InputLabel>Facebook Link</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<TextField name="facebookLink" onChange={handleChange} value={details?.facebookLink} fullWidth size="small" />
				</Grid>
			</Grid>

			<Divider />
			<Grid container>
				<Grid item xs={4}>
					<InputLabel>Instagram Link</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<TextField name="instagramLink" onChange={handleChange} value={details?.instagramLink} fullWidth size="small" />
				</Grid>
			</Grid>
			<Divider />
			<Grid container>
				<Grid item xs={4}>
					<InputLabel>Youtube Link</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<TextField name="youtubeLink" onChange={handleChange} value={details?.youtubeLink} fullWidth size="small" />
				</Grid>
			</Grid>
			<Divider />

			<Grid container>
				<Grid item xs={4}>
					<InputLabel>LinkedIn Link</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<TextField name="linkedinLink" onChange={handleChange} value={details?.linkedinLink} fullWidth size="small" />
				</Grid>
			</Grid>

			<Divider />
			<Grid container>
				<Grid item xs={4}>
					<InputLabel>Twitter Link</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<TextField name="xLink" onChange={handleChange} value={details?.xLink} fullWidth size="small" />
				</Grid>
			</Grid>

			<Divider />

			<Stack py={2} mt="auto" direction="row" gap={1} justifyContent="flex-end">
				<Button loading={loading} style={{ width: 100 }} onClick={handleUpdate} type="primary">
					Save
				</Button>
			</Stack>
		</Stack>
	);
}

import { ButtonBase, Link, Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { AppColors } from '../Utils/helper/AppColors';
import { UploadIcon } from './Icons/Icons';

export default function DragDropUpload(props) {
	const { borderColor, subText, sampleFile, onUpload, extra } = props;

	const fileRef = useRef();

	const downloadSample = (e) => {
		e.stopPropagation();
		const link = document.createElement('a');
		link.href = `/sample/${sampleFile}`;
		link.download = sampleFile;
		link.click();
	};

	return (
		<ButtonBase
			sx={{ borderRadius: '12px', border: `1px solid ${borderColor || AppColors.BORDER_GREY}` }}
			onClick={() => fileRef.current.click()}
		>
			<input type="file" ref={fileRef} hidden onChange={onUpload} />
			<Stack alignItems="center" justifyContent="center" width="100%" py={2} px={4} gap={0.5}>
				<Stack
					alignItems="center"
					justifyContent="center"
					height={40}
					width={40}
					borderRadius={2}
					border="1px solid"
					borderColor={AppColors.BORDER_GREY}
				>
					<UploadIcon color={AppColors.TEXT_SECONDARY} size={20} />
				</Stack>

				<Typography variant="subtitle2">
					<span style={{ color: AppColors.PRIMARY_DARK, fontWeight: 600 }}>Click to upload</span> or drag and drop
				</Typography>

				<Stack direction="row" alignItems="center" gap={1}>
					<Typography variant="subtitle2">{subText}</Typography>
					{sampleFile && (
						<Link color={AppColors.PRIMARY_DARK} fontSize={13} onClick={downloadSample}>
							Download Sample file
						</Link>
					)}
				</Stack>

				{extra && (
					<Typography variant="subtitle2" fontSize={12}>
						{extra}
					</Typography>
				)}
			</Stack>
		</ButtonBase>
	);
}

import {
	Divider,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import React from 'react';
import moment from 'moment-timezone';
import { AppColors } from '../../../Utils/helper/AppColors';
import { InfoIcon } from '../../../Components/Icons/Icons';

export default function CampaignDetailsReports({ details }) {
	const HistoryItem = ({ history, details }) => (
		<Stack direction="row" alignItems="flex-start" gap={1}>
			<Typography flexShrink={0} variant="subtitle2" fontSize={12}>
				{history?.callTime && moment(history?.callTime).tz(details?.userId?.timezone).format('lll')}
			</Typography>
			<Typography variant="subtitle2">-</Typography>
			<Typography variant="subtitle1" fontSize={12}>
				{history?.endedReason?.replaceAll('-', ' ')}
			</Typography>
		</Stack>
	);

	return (
		<TableContainer sx={{ height: 'calc(100vh - 188px)' }}>
			<Table stickyHeader>
				<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
					<TableCell>Name</TableCell>
					<TableCell>Email / Phone</TableCell>
					<TableCell>Scheduled Time</TableCell>
					<TableCell>Call Time</TableCell>
					<TableCell>Status</TableCell>
					<TableCell>History</TableCell>
				</TableHead>

				<TableBody>
					{details?.compaignUser?.map((i, index) => {
						return (
							<TableRow sx={{ '& .MuiTableCell-root': { verticalAlign: 'top' } }} key={index}>
								<TableCell>
									<Typography variant="subtitle2">{i?.userId?.firstName + ' ' + i?.userId?.lastName}</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2">{i?.userId?.phoneNumber}</Typography>
									<Typography variant="subtitle2">{i?.userId?.email}</Typography>
								</TableCell>
								<TableCell>
									<Stack direction="row" alignItems="center" gap={0.5}>
										<Typography variant="subtitle2">
											{i?.startDateTime && moment(i?.startDateTime).tz(i?.userId?.timezone).format('lll')}
										</Typography>
									</Stack>
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2">{i?.call?.createdAt && moment(i?.call?.createdAt).format('lll')}</Typography>
								</TableCell>
								<TableCell>
									<Stack direction="row" alignItems="center" gap={0.5}>
										<Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
											{i?.call?.status}
										</Typography>

										{i?.call?.endedReason && (
											<Tooltip title={i?.call?.endedReason?.replaceAll('-', ' ')}>
												<Stack>
													<InfoIcon />
												</Stack>
											</Tooltip>
										)}
									</Stack>
								</TableCell>

								<TableCell>
									{i?.callHistory?.length > 0 && (
										<Stack gap={1} divider={<Divider />}>
											{i?.callHistory?.map((history, index) => (
												<HistoryItem key={index} history={history} details={i} />
											))}
										</Stack>
									)}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

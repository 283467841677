import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import { useNavigate, useParams } from 'react-router-dom';
import CultService from '../../Services/CultService';
import { PATH_NAME } from '../../Routes/Pathname';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import PageLoader from '../../Components/PageLoader';
import CampaignDetailsReports from './Components/CampaignDetailsReports';
import CampaignDetailsInsights from './Components/CampaignDetailsInsights';
import { useSelector } from 'react-redux';
import CampaignPreview from './Components/CampaignSteps/CampaignPreview';

export default function CampaignDetails() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [details, setDetails] = useState({});
	const [loading, setLoading] = useState(false);
	const [value, setValue] = React.useState(0);
	const company = useSelector((state) => state?.app?.company);
	const [insights, setInsights] = useState([]);

	const handleChange = (event, newValue) => {
		if (newValue !== null) {
			setValue(newValue);
		}
	};

	const getDetails = async () => {
		setLoading(true);
		try {
			let res = await CultService.getCampaignById({ params: { id } });
			if (res.status == 200) {
				setDetails(res.data);
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const getCampaignInsights = async () => {
		setLoading(true);
		try {
			let res = await CultService.getCampaignInsights({ params: { companyId: company?._id, campaigns: [id] } });
			setInsights(res.data.data);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getDetails();
	}, []);

	useEffect(() => {
		if (company) {
			getCampaignInsights();
		}
	}, [company]);

	const tabs = [
		{
			code: 'Preview',
			Component: CampaignPreview,
			props: { details, viewOnly: true },
		},
		{
			code: 'Insights',
			Component: CampaignDetailsInsights,
			props: { insights },
		},
		{
			code: 'Reports',
			Component: CampaignDetailsReports,
			props: { details },
		},
	];

	// function transformData(data) {
	// 	return data?.flatMap((cluster) =>
	// 		cluster?.alignmentIssues?.map((issue) => ({
	// 			clusterName: cluster.clusterName,
	// 			name: issue.name,
	// 			solution: issue.solution,
	// 			employeeReports: issue.employeeReports,
	// 			percentage: issue.percentage,
	// 			_id: issue._id,
	// 		})),
	// 	);
	// }

	// useEffect(() => {
	// 	setItems(transformData(details?.insights));
	// }, [details]);

	return (
		<Stack>
			<PageHeading
				onBackClick={() => navigate(PATH_NAME.CAMPAIGN_LIST)}
				title={details?.basicDetails?.name}
				subTitle={details?.basicDetails?.description}
			/>

			<Stack mt={4} gap={2}>
				<ToggleButtonGroup size="small" color="primary" value={value} exclusive onChange={handleChange}>
					{tabs?.map((tab, index) => (
						<ToggleButton
							key={index}
							sx={{ textTransform: 'none', px: 2, borderRadius: '8px', alignItems: 'center' }}
							value={index}
						>
							<Typography variant="subtitle2" fontSize={13} fontWeight={500}>
								{tab?.code}
							</Typography>
						</ToggleButton>
					))}
				</ToggleButtonGroup>

				{tabs.map(({ Component, props }, index) => index == value && <Component key={index} {...props} />)}
			</Stack>

			{loading && <PageLoader />}
		</Stack>
	);
}

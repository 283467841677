// export const timeoutPromise = new Promise((resolve, reject) => {
// 	setTimeout(() => {
// 		reject(new Error('Request timed out achcccc'));
// 	}, 10000);
// });

export const randomIntArrayInRange = (min, max, n = 1) =>
	Array.from({ length: n }, () => Math.floor(Math.random() * (max - min + 1)) + min);

export const validateEmail = (email) => {
	let res = false;
	if (
		String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			)
	) {
		res = true;
	}
	return res;
};

export const filterBySearch = (items, term, keys) => {
	if (!term) return items;
	const getNestedValue = (obj, path) => {
		return path?.split('.')?.reduce((value, key) => {
			return value ? value[key] : undefined;
		}, obj);
	};
	return items?.filter((item) =>
		keys?.some((key) => {
			const value = getNestedValue(item, key);
			return value?.toString()?.toLowerCase()?.includes(term?.toLowerCase());
		}),
	);
};

export const convertToQueryParams = (filters) => {
	const singularKeyMap = {
		genders: 'gender',
		roles: 'role',
		timezones: 'timezone',
		departments: 'department',
	};

	const queryParams = Object.keys(filters)
		.map((key) => `${singularKeyMap[key] || key}=${filters[key].join(',')}`)
		.join('&');

	return queryParams;
};

export const getInitials = function ({ user }) {
	let firstName = user?.firstName;
	let lastName = user?.lastName;
	let initials = !firstName && !lastName ? 'na' : (firstName?.[0] || '') + (lastName?.[0] || '');
	return initials.toUpperCase();
};

export const copyToClipboard = (url) => {
	const dummy = document.createElement('input');
	document.body.appendChild(dummy);
	dummy.value = url;
	dummy.select();
	dummy.setSelectionRange(0, Math.max(url?.length + 1, 99999));

	if (navigator?.clipboard?.writeText) {
		navigator.clipboard.writeText(dummy.value).then(() => document.body.removeChild(dummy));
	} else {
		document.execCommand('copy');
		document.body.removeChild(dummy);
	}
};

export function camelCaseToCapitalized(camelCaseString) {
	return camelCaseString
		.replace(/([a-z])([A-Z])/g, '$1 $2') // Add spaces before uppercase letters
		.replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
}

export const extractNumberFromString = (string) => {
	return parseFloat(string.replace('%', ''));
};

import { Divider, Grid, InputAdornment, InputLabel, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_NAME } from '../../Routes/Pathname';
import { HiOutlineMail } from 'react-icons/hi';
import { Button, Select } from 'antd';
import { countries } from '../../Utils/countries';
import { timezones } from '../../Utils/timezones';
import CultService from '../../Services/CultService';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import CountryCode from './Components/CountryCode';
import { DEPARTMENTS, GENDERS } from '../../Utils/helper/AppConstants';
import { validateEmail } from '../../Utils/helper/commonUtils';
import { useSelector } from 'react-redux';

export default function AddEditEmployee() {
	const navigate = useNavigate();
	const { state } = useLocation();
	const company = useSelector((state) => state?.app?.company)?._id;
	const edit = state?.user?._id;

	const [details, setDetails] = useState({});
	const [countryCode, setCountryCode] = useState('+1');
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});

	const [depts, setDepts] = useState([]);

	const handleInputChange = (e) => {
		setDetails({ ...details, [e.target.name]: e.target.value });
	};

	const getDepts = async () => {
		let res = await CultService.getFilterValues({ params: { companyId: company } });
		setDepts(res?.data?.departments);
	};

	useEffect(() => {
		if (company) getDepts();
	}, [company]);

	useEffect(() => {
		if (state?.user) {
			setDetails(state?.user);
		}
	}, []);

	useEffect(() => {
		if (edit) {
			setDetails(state?.user);
		}
	}, [edit]);

	const handleCreate = async () => {
		setLoading(true);
		try {
			const data = {
				...details,
				company,
				phoneNumber: details?.phoneNumber ? countryCode + details?.phoneNumber : '',
			};
			let res = await CultService.createUser({ data });
			if (res.status == 200) {
				toast.success(HelperTexts.CREATED_SUCCESSFULLY);
				navigate(PATH_NAME.EMPLOYEES_LIST);
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const handleUpdate = async () => {
		setLoading(true);
		try {
			let res = await CultService.updateUser({
				data: { ...details },
				params: { id: state?.user?._id },
			});
			if (res.status == 200) {
				toast.success(HelperTexts.UPDATED_SUCCESSFULLY);
				navigate(PATH_NAME.EMPLOYEES_LIST);
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const setError = (e) => {
		if (e.target.name == 'email' && !validateEmail(e.target.value)) {
			setErrors({ ...errors, [e.target.name]: 'Invalid' });
		} else {
			setErrors({ ...errors, [e.target.name]: null });
		}
	};

	const hasError = () => {
		let res;
		if (Object.values(errors).some((value) => value !== null)) {
			res = true;
		} else {
			res = false;
		}
		return res;
	};

	const isDisabled = () => {
		let res = false;
		if (hasError()) {
			res = true;
		}
		return res;
	};

	const handleSelectDepartment = (newValue) => {
		const selectedValue = newValue.slice(-1);
		setDetails({ ...details, department: selectedValue[0] });
		if (selectedValue.length && !depts.includes(selectedValue[0])) {
			setDepts([...depts, selectedValue[0]]);
		}
	};

	return (
		<Stack sx={{ '& .MuiDivider-root': { my: 2 } }}>
			<PageHeading title={`${edit ? 'Edit' : 'Add'} Employee`} />

			<Stack mt={4}>
				<Typography variant="body1">Personal info</Typography>
				<Typography variant="subtitle2">Update user’s photo and personal details here.</Typography>
			</Stack>

			<Divider />

			<Grid container spacing={2}>
				<Grid item xs={3}>
					<InputLabel>Name</InputLabel>
				</Grid>
				<Grid item xs={3}>
					<TextField
						value={details?.firstName}
						name="firstName"
						onChange={handleInputChange}
						fullWidth
						placeholder="First Name"
						size="small"
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						value={details?.lastName}
						name="lastName"
						onChange={handleInputChange}
						fullWidth
						placeholder="Last Name"
						size="small"
					/>
				</Grid>
			</Grid>

			<Divider />

			<Grid container>
				<Grid item xs={3}>
					<InputLabel>Email address</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<TextField
						error={errors?.email}
						onBlur={setError}
						name="email"
						value={details?.email}
						onChange={handleInputChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<HiOutlineMail size={20} color="#667085" />
								</InputAdornment>
							),
						}}
						fullWidth
						placeholder="Email"
						size="small"
					/>
				</Grid>
			</Grid>

			<Divider />

			{/* <Grid container>
				<Grid item xs={3}>
					<InputLabel>Your Photo</InputLabel>
					<InputLabel sx={{ color: AppColors.TEXT_TERTIARY, fontWeight: 400 }}>
						This will be displayed on your profile.
					</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<Stack direction="row" alignItems="flex-start" gap={2}>
						<Avatar sx={{ height: 50, width: 50 }} />
						<DragDropUpload borderColor={AppColors.PRIMARY_LIGHT} subText="SVG, PNG, JPG or GIF (max. 800x400px)" />
					</Stack>
				</Grid>
			</Grid> */}

			{/* <Divider /> */}

			<Grid container>
				<Grid item xs={3}>
					<InputLabel>Role</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<TextField value={details?.role} name="role" onChange={handleInputChange} fullWidth placeholder="Role" size="small" />
				</Grid>
			</Grid>

			<Divider />

			<Grid container>
				<Grid item xs={3}>
					<InputLabel>Department</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<Select
						mode="tags"
						value={details?.department}
						style={{ width: '100%' }}
						placeholder="Select or type to add"
						onChange={handleSelectDepartment}
						allowClear
					>
						{depts?.map((option) => (
							<Select.Option key={option} value={option}>
								{option}
							</Select.Option>
						))}
					</Select>
				</Grid>
			</Grid>

			{console.log(details)}

			<Divider />

			<Grid container>
				<Grid item xs={3}>
					<InputLabel>Gender</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<Select
						onChange={(e) => setDetails({ ...details, gender: e })}
						value={details?.gender}
						style={{ width: '100%' }}
						showSearch
						placeholder="Select Gender"
						options={GENDERS.map((i) => {
							return {
								label: i.name,
								value: i.code,
							};
						})}
					/>
				</Grid>
			</Grid>

			<Divider />

			<Grid container>
				<Grid item xs={3}>
					<InputLabel>Phone Number</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<TextField
						helperText={edit && 'Please ensure that the phone numbers you provide include the correct country code'}
						InputProps={{
							startAdornment: !edit ? <CountryCode countryCode={countryCode} setCountryCode={setCountryCode} /> : null,
						}}
						value={details?.phoneNumber}
						fullWidth
						placeholder="Enter Phone Number"
						size="small"
						name="phoneNumber"
						onChange={handleInputChange}
					/>
				</Grid>
			</Grid>

			<Divider />

			<Grid container>
				<Grid item xs={3}>
					<InputLabel>Time Zone</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<Select
						onChange={(e) => setDetails({ ...details, timezone: e })}
						value={details?.timezone}
						style={{ width: '100%' }}
						showSearch
						placeholder="Select Timezone"
						options={timezones.map((i) => {
							return {
								label: i.zone,
								value: i.zone,
							};
						})}
					/>
				</Grid>
			</Grid>

			<Divider />

			<Grid container>
				<Grid item xs={3}>
					<InputLabel>Country</InputLabel>
				</Grid>
				<Grid item xs={6}>
					<Select
						onChange={(e) => setDetails({ ...details, country: e })}
						value={details?.country}
						style={{ width: '100%' }}
						showSearch
						placeholder="Select Country"
						options={countries.map((i) => {
							return {
								label: i.name,
								value: i.name,
							};
						})}
					/>
				</Grid>
			</Grid>

			<Divider />

			<Stack direction="row" gap={1} justifyContent="flex-end">
				<Button style={{ width: 100 }} onClick={() => navigate(PATH_NAME.EMPLOYEES_LIST)} type="default">
					Cancel
				</Button>
				<Button
					disabled={isDisabled()}
					style={{ width: 100 }}
					loading={loading}
					onClick={edit ? handleUpdate : handleCreate}
					type="primary"
				>
					Save
				</Button>
			</Stack>
		</Stack>
	);
}

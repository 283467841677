import { ButtonBase } from '@mui/material';
import React from 'react';
import { AppColors } from '../Utils/helper/AppColors';

export default function IconButton(props) {
	const { onClick, icon, size, color, sx, rounded } = props;
	return (
		<ButtonBase
			onClick={onClick}
			sx={{
				...sx,
				minWidth: size || 40,
				minHeight: size || 40,
				bgcolor: color || AppColors.PRIMARY_LIGHT,
				borderRadius: rounded ? '50px' : '8px',
			}}
		>
			{icon}
		</ButtonBase>
	);
}

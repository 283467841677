import { Button, Drawer, Grid, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import { AddIcon, DotIcon } from '../../Components/Icons/Icons';
import { AppColors } from '../../Utils/helper/AppColors';
import StatisticsCard from './Components/StatisticsCard';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import { MdFilterList } from 'react-icons/md';
import { AiOutlineClockCircle, AiOutlineDollar } from 'react-icons/ai';
import SelectViewModal from './Components/SelectViewModal';
import { DASHBOARD_VIEWS } from '../../Utils/helper/AppConstants';
import CultService from '../../Services/CultService';
import toast from 'react-hot-toast';
import PageLoader from '../../Components/PageLoader';
import LineGraphCard from './Components/LineGraphCard';
import FooterCard from './Components/FooterCard';
import { useSelector } from 'react-redux';
import FilterSelection from '../../Components/FilterSelection';
import { useNavigate } from 'react-router-dom';
import { PATH_NAME } from '../../Routes/Pathname';
import CreateCampaignModal from '../Campaign/Components/CreateCampaignModal';

export default function Dashboard() {
	const [loading, setLoading] = useState(false);
	const [viewModal, setViewModal] = useState(false);
	const [filterDrawer, setFilterDrawer] = useState(false);
	const navigate = useNavigate();
	const [filters, setFilters] = useState([]);
	const [open, setOpen] = useState(false);

	const [selectedView, setSelectedView] = useState(DASHBOARD_VIEWS.ORGANIZATIONAL);
	const [selectedFilter, setSelectedFilter] = useState([]);
	const [additionalFilters, setAdditionalFilters] = useState([]);
	const [details, setDetails] = useState({});
	const company = useSelector((state) => state?.app?.company);

	const getDastboardStats = async () => {
		setLoading(true);
		const data = {};
		data.companyId = company?._id;
		data.viewType = selectedView?.LABEL;
		data.filters = selectedFilter?.values || undefined;
		data.filterType = selectedFilter?.type || undefined;
		data.additionalFilters = additionalFilters.length > 0 ? additionalFilters.map((i) => i?.code) : undefined;
		try {
			let res = await CultService.getDashboardStats({ data });
			setDetails(res?.data?.data);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const getFilters = async () => {
		let res = await CultService.getFilterValues({ params: { companyId: company?._id } });
		setFilters(res?.data);
	};

	useEffect(() => {
		if (company) {
			getDastboardStats();
			getFilters();
		}
	}, [selectedView, selectedFilter, company]);

	return (
		<Stack>
			<PageHeading
				title="Dashboard"
				subTitle="Your current summary and activity."
				renderRight={
					<Stack direction="row" alignItems="center" gap={2}>
						<Button
							onClick={() => setViewModal(true)}
							variant="outlined"
							color="secondary"
							startIcon={<AddIcon color={AppColors.TEXT_PRIMARY} />}
						>
							Views
						</Button>
						<Button onClick={() => setFilterDrawer(true)} variant="outlined" color="secondary" startIcon={<MdFilterList />}>
							Filters
						</Button>
						<Button onClick={() => setOpen(true)} variant="contained" startIcon={<AddIcon color={AppColors.WHITE} />}>
							Create Campaign
						</Button>
					</Stack>
				}
			/>

			<SelectViewModal
				open={viewModal}
				onClose={() => setViewModal(false)}
				selectedView={selectedView}
				setSelectedView={setSelectedView}
			/>

			<Drawer anchor="right" open={filterDrawer} onClose={() => setFilterDrawer(false)}>
				<FilterSelection
					filters={filters}
					selectedFilter={selectedFilter}
					setSelectedFilter={setSelectedFilter}
					setAdditionalFilters={setAdditionalFilters}
					selectedView={selectedView}
					onClose={() => setFilterDrawer(false)}
				/>
			</Drawer>

			<Stack mt={3}>
				<Typography sx={{ textTransform: 'capitalize' }} mb={2} variant="body1">
					{selectedView.LABEL} View
				</Typography>

				<Grid container spacing={2}>
					<Grid item xs={12} md={4}>
						<StatisticsCard
							additionalFilters={additionalFilters}
							selectedFilter={selectedFilter}
							selectedView={selectedView}
							title="Response Rate"
							tooltip="Response rate represents the percentage of  employees that have responded to the call out of the total number of employees who were reached."
							value={details?.responseRate}
							monthChange={10}
						/>
					</Grid>

					<Grid item xs={12} md={4}>
						<StatisticsCard
							additionalFilters={additionalFilters}
							selectedFilter={selectedFilter}
							selectedView={selectedView}
							title="Leadership Trust Score"
							tooltip="Leadership Trust Score gauges employee confidence in leadership's integrity, competence and reliability, directly impacting workplace morale and engagement"
							value={details?.leadershipTrustScore}
							monthChange={10}
						/>
					</Grid>

					<Grid item xs={12} md={4}>
						<StatisticsCard
							additionalFilters={additionalFilters}
							selectedFilter={selectedFilter}
							selectedView={selectedView}
							title="Sentiment Score"
							tooltip="Sentiment Score captures the collective emotional tone and attitudes of employees towards the organization, reflecting overall morale and workplace culture"
							value={details?.sentimentScore}
							monthChange={10}
						/>
					</Grid>
				</Grid>
			</Stack>

			{additionalFilters.length > 0 && (
				<Stack ml={2} direction="row" alignItems="center" gap={2} mt={1}>
					{additionalFilters?.map((i, index) => (
						<Stack key={index} direction="row" alignItems="center" gap={1}>
							<DotIcon size={10} color={i.barColor} />
							<Typography sx={{ textTransform: 'capitalize' }} variant="subtitle2">
								{i.code.toLowerCase()}
							</Typography>
						</Stack>
					))}
				</Stack>
			)}

			<Grid mt={1} spacing={3} container>
				<Grid item xs={12} md={6}>
					<LineGraphCard
						value={null}
						values={details?.employeeSatisfactionTrend}
						title="Employee Satisfaction Trend"
						additionalFilters={additionalFilters}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<LineGraphCard
						value={null}
						values={details?.eNPS}
						title="Employee Net Promoter Score"
						additionalFilters={additionalFilters}
					/>
				</Grid>
			</Grid>

			<Stack direction={{ xs: 'column', md: 'row' }} gap={3} mt={3}>
				<FooterCard
					background="linear-gradient(to right, #2D60FF,#539BFF)"
					title="Hours Saved"
					icon={<AiOutlineClockCircle />}
					value={`${details?.hoursSaved || '--'} Hrs`}
				/>

				<FooterCard
					background="linear-gradient(to right, #4C49ED,#0A06F4)"
					title="Tentative Cost Saved"
					icon={<AiOutlineDollar />}
					value={`$${details?.costSaved || '--'}`}
				/>
			</Stack>

			{loading && <PageLoader />}

			<Modal type="blur" open={open}>
				<CreateCampaignModal open={open} setOpen={setOpen} fetch={() => navigate(PATH_NAME.CAMPAIGN_LIST)} />
			</Modal>
		</Stack>
	);
}

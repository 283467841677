import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import PageLoader from '../../Components/PageLoader';
import IssuesFilter from './Components/IssuesFilter';
import KeyIssues from './Components/KeyIssues';
import CampaignIssues from './Components/CampaignIssues';

export default function IssuesRaised() {
	const [loading, setLoading] = useState(false);
	const [filterQuery, setFilterQuery] = useState(null);

	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const tabs = [
		{
			code: 'Key Issues',
			Component: KeyIssues,
		},
		{
			code: 'Campaign View',
			Component: CampaignIssues,
		},
	];

	return (
		<Stack>
			{loading && <PageLoader />}
			<PageHeading title="Issues raised" subTitle="Track, manage and resolve employees raised issues" />
			<Stack mt={4} gap={2}>
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<ToggleButtonGroup size="small" color="primary" value={value} exclusive onChange={handleChange}>
						{tabs?.map((tab, index) => (
							<ToggleButton
								key={index}
								sx={{ textTransform: 'none', px: 2, borderRadius: '8px', alignItems: 'center' }}
								value={index}
							>
								<Typography variant="subtitle2" fontSize={13} fontWeight={500}>
									{tab?.code}
								</Typography>
							</ToggleButton>
						))}
					</ToggleButtonGroup>
					{/* <IssuesFilter setFilterQuery={setFilterQuery} /> */}
				</Stack>

				{tabs.map(({ Component, props }, index) => index == value && <Component key={index} {...props} />)}
			</Stack>
		</Stack>
	);
}

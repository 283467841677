import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CultService from '../../../Services/CultService';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../../Utils/helper/HelperTexts';
import SelectCampaign from '../../../Components/SelectCampaign';
import { AppColors } from '../../../Utils/helper/AppColors';
import UserThumbnail from '../../../Components/User/UserThumbnail';
import DotChip from '../../../Components/DotChip';
import DepartmentTag from '../../../Components/DepartmentTag';
import CampaignEmptyState from '../../../Components/CampaignEmptyState';

export default function CampaignIssues() {
	const company = useSelector((state) => state?.app?.company);
	const [issues, setIssues] = useState([]);
	const [filterQuery, setFilterQuery] = useState(null);

	const handleSelectCampaign = (i, option) => {
		setFilterQuery((prev) => ({ ...prev, campaigns: [option?.item] }));
	};

	const getIssues = async () => {
		try {
			let res = await CultService.getCallIssues({
				params: {
					companyId: company?._id,
					campaigns: filterQuery?.campaigns?.map((i) => i._id),
				},
			});
			setIssues(res?.data?.data?.issues);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
		}
	};

	useEffect(() => {
		if (company) {
			if (filterQuery?.campaigns?.length > 0) {
				getIssues();
			}
		}
	}, [company, filterQuery]);

	return (
		<Stack gap={2}>
			<SelectCampaign handleSelectCampaign={handleSelectCampaign} />
			{filterQuery?.campaigns?.length > 0 ? (
				issues?.map((c, index) => (
					<TableContainer key={index}>
						<Table stickyHeader>
							<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
								<TableCell width={200}>Name</TableCell>
								<TableCell>Issue</TableCell>
								<TableCell>Category</TableCell>
								<TableCell width={200}>Department</TableCell>
							</TableHead>
							<TableBody>
								{issues?.map((c, index) => (
									<TableRow key={index}>
										<TableCell>
											<UserThumbnail user={{ firstName: c.name }} />
										</TableCell>
										<TableCell>
											<Typography sx={{ textTransform: 'capitalize' }} variant="subtitle2">
												{c?.callIssues?.issues?.join(', ')?.replaceAll('_', ' ')}
											</Typography>
										</TableCell>
										<TableCell>
											{c?.callIssues?.category && (
												<DotChip color={AppColors.TEXT_SUCCESS} label={c?.callIssues?.category?.replaceAll('_', ' ')} />
											)}
										</TableCell>
										<TableCell>
											<DepartmentTag department={c?.department} />
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				))
			) : (
				<CampaignEmptyState text="Select a campaign to view issues." />
			)}
		</Stack>
	);
}

import { Card, Divider, IconButton, Modal, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AddIcon, BackIcon, CloseIcon, EditIcon } from '../../../Components/Icons/Icons';
import { AppColors } from '../../../Utils/helper/AppColors';
import IconContainer from '../../../Components/IconContainer';
import moment from 'moment';
import _ from 'lodash';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: AppColors.WHITE,
	borderRadius: 3,
};

export default function NotesColumn({ comments, handleAddComment, id }) {
	const [show, setShow] = useState(false);
	const [text, setText] = useState('');

	const handleSend = () => {
		handleAddComment({ payload: { text, compaignUserId: id }, onComplete: () => setText('') });
	};

	comments = comments?.sort((a, b) => {
		return new Date(b?.createdAt) - new Date(a?.createdAt);
	});

	return (
		<Stack>
			<EditIcon cursor="pointer" onClick={() => setShow(true)} color={AppColors.TEXT_TERTIARY} size={18} />
			<Modal anchor="right" open={show} onClose={() => setShow(false)}>
				<Stack height="calc(100vh - 120px)" width={500} sx={style}>
					<Stack p={2} direction="row" alignItems="center" justifyContent="space-between">
						<IconButton onClick={() => setShow(false)} size="small">
							<BackIcon />
						</IconButton>
						<Typography variant="body1">Notes</Typography>
						<IconButton onClick={() => setShow(false)} size="small">
							<CloseIcon />
						</IconButton>
					</Stack>

					<Divider />

					<Stack height="100%" overflow="auto" p={3}>
						<Stack height="100%" overflow="auto" gap={1.5}>
							{comments?.map((comment) => (
								<Stack key={comment?._id} gap={0.5}>
									<Typography variant="subtitle2" fontSize={10}>
										{moment(comment?.createdAt).calendar()}
									</Typography>
									<Card noShadow>
										<Typography variant="subtitle2">{comment.text}</Typography>
									</Card>
								</Stack>
							))}
						</Stack>
						<Stack direction="row" gap={2} mt={2}>
							<TextField value={text} onChange={(e) => setText(e.target.value)} placeholder="Add Note" fullWidth size="small" />
							<IconContainer
								onClick={handleSend}
								color={AppColors.PRIMARY_LIGHT}
								size={40}
								icon={<AddIcon color={AppColors.WHITE} />}
							/>
						</Stack>
					</Stack>
				</Stack>
			</Modal>
		</Stack>
	);
}

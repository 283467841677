import { Avatar, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AppColors } from '../../Utils/helper/AppColors';
import UserDetails from './UserDetails';
import { getInitials } from '../../Utils/helper/commonUtils';
import toast from 'react-hot-toast';

export default function UserThumbnail({ user, hideName, size, vertical, userId }) {
	const [showDetails, setShowDetails] = useState(false);

	const getUsername = function () {
		let username = user?.anonymous ? '****' : (user?.firstName || user?.name)?.split(' ')?.[0]?.toLowerCase();
		return `@${username || 'na'}`;
	};

	const handleUser = () => {
		if (userId) {
			setShowDetails(true);
		}
	};

	return (
		<>
			<Stack
				onClick={handleUser}
				sx={{ cursor: 'pointer' }}
				direction={vertical ? 'column' : 'row'}
				alignItems="center"
				gap={1.4}
			>
				{user?.image ? (
					<Avatar
						sx={{ height: size || 34, width: size || 34 }}
						src="https://img.freepik.com/free-photo/portrait-young-woman-with-natural-make-up_23-2149084942.jpg"
					/>
				) : (
					<Stack
						flexShrink={0}
						height={size || 34}
						width={size || 34}
						borderRadius={50}
						bgcolor={AppColors.BORDER_GREY}
						border="1px solid"
						borderColor="#dee1e3"
						alignItems="center"
						justifyContent="center"
						sx={{ cursor: 'pointer', transition: 'all .2s', ':hover': { bgcolor: '#e4e6eb' } }}
					>
						<Typography variant="subtitle2">{getInitials({ user })}</Typography>
					</Stack>
				)}
				{!hideName && (
					<Typography variant="subtitle2" fontWeight={500}>
						{user?.anonymous ? 'Anonymous' : `${user?.firstName || ''} ${user?.lastName || ''}`}
					</Typography>
				)}
			</Stack>

			<Modal open={showDetails} onClose={() => setShowDetails(false)}>
				<UserDetails onClose={() => setShowDetails(false)} userId={userId} />
			</Modal>
		</>
	);
}

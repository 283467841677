import { ButtonBase } from '@mui/material';
import React from 'react';
import { AppColors } from '../Utils/helper/AppColors';

export default function IconContainer({ size = 48, icon, color, onClick }) {
	return (
		<ButtonBase
			onClick={onClick}
			sx={{
				height: size,
				width: size,
				borderRadius: '8px',
				alignItems: 'center',
				justifyContent: 'center',
				border: '1px solid',
				borderColor: color || AppColors.BORDER_GREY,
				bgcolor: color || AppColors.WHITE,
				flexShrink: 0,
				pointerEvents: typeof onClick == 'function' ? 'auto' : 'none',
				svg: {
					fontSize: 22,
					color: '#344054',
				},
			}}
		>
			{icon}
		</ButtonBase>
	);
}

import { Card, Stack, Typography } from '@mui/material';
import React from 'react';
import { CiSearch } from 'react-icons/ci';

export default function CampaignEmptyState({ text }) {
	return (
		<Card noShadow sx={{ height: 200 }}>
			<Stack alignItems="center" justifyContent="center" gap={1} width="100%" height="100%">
				<CiSearch color="#667085" size={60} />
				<Typography variant="subtitle2" fontSize={16}>
					{text}
				</Typography>
			</Stack>
		</Card>
	);
}

import { MenuItem, Popover, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CountryCodesList } from './CountryCodesList';
import SearchBar from '../../../Components/SearchBar';
import { AppColors } from '../../../Utils/helper/AppColors';
import { filterBySearch } from '../../../Utils/helper/commonUtils';
import { MdArrowDropDown } from 'react-icons/md';

export default function CountryCode({ countryCode, setCountryCode }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [search, setSearch] = useState('');

	const handleClose = () => {
		setAnchorEl(null);
		setSearch('');
	};

	return (
		<>
			<Stack
				onClick={(e) => setAnchorEl(e.currentTarget)}
				sx={{ cursor: 'pointer' }}
				p={1}
				mr={1}
				ml={-1.6}
				borderRadius="inherit"
				bgcolor="#f2f2f2"
				direction="row"
				alignItems="center"
				gap={0.5}
			>
				<Typography variant="subtitle2" fontSize={15} color={AppColors.TEXT_PRIMARY}>
					{countryCode}
				</Typography>
				<MdArrowDropDown size={22} />
			</Stack>

			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Stack sx={{ '& .MuiTypography-root': { textAlign: 'left' } }} p={1} gap={0.5}>
					<SearchBar search={search} setSearch={setSearch} placeholder="Search Country" />
					<Stack width={380} maxHeight={400} overflow="auto" justifyContent="flex-start" alignItems="flex-start">
						{filterBySearch(CountryCodesList, search, ['name', 'dial_code'])?.map((i, index) => (
							<MenuItem
								key={index}
								sx={{ gap: 1, width: '100%', justifyContent: 'flex-start', p: 0.5, borderRadius: 1, px: 1.5 }}
								onClick={() => {
									setCountryCode(i.dial_code);
									handleClose();
								}}
							>
								<Typography variant="subtitle2">{i.name}</Typography>
								<Typography variant="subtitle1">{i.dial_code}</Typography>
							</MenuItem>
						))}
					</Stack>
				</Stack>
			</Popover>
		</>
	);
}

import React, { useEffect, useState } from 'react';
import { AppColors } from '../../Utils/helper/AppColors';
import {
	Avatar,
	Card,
	Divider,
	Grid,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { AddIcon, BackIcon, CheckIcon, CloseIcon, CopyIcon, DeleteIcon } from '../Icons/Icons';
import { copyToClipboard, getInitials } from '../../Utils/helper/commonUtils';
import CultService from '../../Services/CultService';
import moment from 'moment';
import CircularProgress from '../../Screens/Dashboard/Components/CircularProgress';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import DotChip from '../DotChip';
import CallRecordingsList from '../../Screens/Recordings/Components/CallRecordingsList';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: AppColors.WHITE,
	borderRadius: 3,
};

const DetailsItem = ({ label, value, copy }) => (
	<Card noShadow sx={{ p: 1.5, py: 1 }}>
		<Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
			<Stack gap={0.5}>
				<Typography variant="subtitle2" fontSize={11}>
					{label}
				</Typography>
				<Typography variant="subtitle1">{value}</Typography>
			</Stack>

			{copy && (
				<IconButton onClick={() => copyToClipboard(value)} size="small">
					<CopyIcon />
				</IconButton>
			)}
		</Stack>
	</Card>
);

export default function UserDetails({ userId, onClose }) {
	const [details, setDetails] = useState({});
	const [issues, setIssues] = useState([]);
	const [recordings, setRecordings] = useState([]);
	const company = useSelector((state) => state?.app?.company);

	const getUserDetails = async () => {
		let res = await CultService.getUserById({ params: { id: userId } });
		if (res.status == 200) {
			setDetails(res?.data?.data);
		}
	};

	const getIssues = async () => {
		try {
			let res = await CultService.getCallIssues({
				params: {
					companyId: company?._id,
					users: [userId],
				},
			});
			setIssues(res?.data?.data?.issues);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
		}
	};

	const getRecordings = async () => {
		try {
			let res = await CultService.getCallRecordings({
				params: {
					companyId: company?._id,
					users: [userId],
				},
			});
			setRecordings(res?.data?.data?.recordings);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
		}
	};

	console.log(recordings);

	useEffect(() => {
		if (company) {
			getIssues();
			getRecordings();
			getUserDetails();
		}
	}, [company]);

	return (
		<Stack sx={style} width="65vw">
			<Stack p={2} direction="row" alignItems="center" gap={1}>
				<IconButton onClick={onClose} size="small">
					<BackIcon />
				</IconButton>
				{details?.image ? (
					<Avatar
						sx={{ height: 34, width: 34 }}
						src="https://img.freepik.com/free-photo/portrait-young-woman-with-natural-make-up_23-2149084942.jpg"
					/>
				) : (
					<Stack
						flexShrink={0}
						height={34}
						width={34}
						borderRadius={50}
						bgcolor={AppColors.BORDER_GREY}
						border="1px solid"
						borderColor="#dee1e3"
						alignItems="center"
						justifyContent="center"
						sx={{ cursor: 'pointer', transition: 'all .2s', ':hover': { bgcolor: '#e4e6eb' } }}
					>
						<Typography variant="subtitle2">{getInitials({ user: details })}</Typography>
					</Stack>
				)}
				<Typography variant="subtitle2" fontWeight={500}>
					{details?.anonymous ? 'Anonymous' : `${details?.firstName || ''} ${details?.lastName || ''}`}
				</Typography>
				<IconButton onClick={onClose} sx={{ ml: 'auto' }} size="small">
					<CloseIcon />
				</IconButton>
			</Stack>
			<Divider />

			<Stack maxHeight="calc(100vh - 120px)" overflow="auto" p={2} gap={2}>
				<Stack gap={1}>
					<Typography ml={1.5} variant="body1">
						Basic Information
					</Typography>
					<Card noShadow>
						<Stack width="100%" direction="row" gap={2} justifyContent="space-between">
							{details?.image ? (
								<Avatar
									sx={{ height: 200, width: 200, borderRadius: 3 }}
									src="https://img.freepik.com/free-photo/portrait-young-woman-with-natural-make-up_23-2149084942.jpg"
								/>
							) : (
								<Stack
									flexShrink={0}
									height={200}
									width={200}
									borderRadius={3}
									bgcolor={AppColors.BORDER_GREY}
									border="1px solid"
									borderColor="#dee1e3"
									alignItems="center"
									justifyContent="center"
								>
									<Typography variant="subtitle2" fontSize={50}>
										{getInitials({ user: details })}
									</Typography>
								</Stack>
							)}

							<Grid container spacing={2}>
								<Grid item xs={6}>
									<DetailsItem label="First Name" value={details?.firstName} />
								</Grid>
								<Grid item xs={6}>
									<DetailsItem label="Last Name" value={details?.lastName} />
								</Grid>
								<Grid item xs={6}>
									<DetailsItem label="Role" value={details?.role} />
								</Grid>
								<Grid item xs={6}>
									<DetailsItem label="Location" value={details?.country} />
								</Grid>
								<Grid item xs={6}>
									<DetailsItem label="Email Address" value={details?.email} copy />
								</Grid>
								<Grid item xs={6}>
									<DetailsItem label="Phone Number" value={details?.phoneNumber} copy />
								</Grid>
							</Grid>
						</Stack>
					</Card>
				</Stack>

				<Stack direction="row" gap={2}>
					<Card noShadow sx={{ width: 180, p: 0 }}>
						<Stack gap={1} height="100%" width="100%" alignItems="center" justifyContent="center">
							<CircularProgress hideBorders size={80} value={75} color="#7F56D9" />
							<Typography textAlign="center" variant="subtitle2">
								Participation Rate
							</Typography>
						</Stack>
					</Card>
					<Card sx={{ p: 0 }} noShadow>
						<Stack p={1.5} width="100%" direction="row" alignItems="center" justifyContent="space-between">
							<Typography variant="body1">Notes</Typography>
							<AddIcon />
						</Stack>

						<Divider sx={{ width: '100%' }} />

						<Stack width="100%" divider={<Divider />}>
							{Array(2)
								.fill('')
								.map((i) => (
									<Stack p={1.5} direction="row" alignItems="flex-start" gap={2}>
										<Typography variant="subtitle2">{moment().format('ll')}</Typography>
										<Typography variant="subtitle2">
											Discuss with her manager to identify immediate steps to improve morale
										</Typography>
										<Stack ml="auto" direction="row" alignItems="center" gap={2}>
											<CheckIcon color="#667085" />
											<DeleteIcon color="#667085" />
										</Stack>
									</Stack>
								))}
						</Stack>
					</Card>
				</Stack>

				<Stack gap={1}>
					<Typography ml={1.5} variant="body1">
						Issues
					</Typography>
					<TableContainer sx={{ minHeight: 'fit-content' }}>
						<Table stickyHeader>
							<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
								<TableCell>Issue</TableCell>
								<TableCell>Category</TableCell>
								<TableCell width={200}>Department</TableCell>
							</TableHead>
							<TableBody>
								{issues?.map((c, index) => (
									<TableRow key={index}>
										<TableCell>
											<Typography sx={{ textTransform: 'capitalize' }} variant="subtitle2">
												{c?.callIssues?.issues?.join(', ')}
											</Typography>
										</TableCell>
										<TableCell>
											{c?.callIssues?.category && <DotChip color={AppColors.TEXT_SUCCESS} label={c?.callIssues?.category} />}
										</TableCell>
										<TableCell>
											<Typography textTransform="capitalize" variant="subtitle2">
												{c?.department?.toLowerCase()}
											</Typography>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>

				<Stack gap={1}>
					<Typography ml={1.5} variant="body1">
						Conversations
					</Typography>
					<CallRecordingsList hideUser height="fit-content" fetch={getRecordings} recordings={recordings} />
				</Stack>
			</Stack>
		</Stack>
	);
}

import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import CultService from '../../Services/CultService';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import { useSelector } from 'react-redux';
import PageLoader from '../../Components/PageLoader';
import CallRecordingsList from './Components/CallRecordingsList';
import RecordingsFilter from './Components/RecordingsFilter';
import { App, DatePicker, Select } from 'antd';
import moment from 'moment';
import CampaignEmptyState from '../../Components/CampaignEmptyState';
import SelectCampaign from '../../Components/SelectCampaign';
import { DotIcon } from '../../Components/Icons/Icons';
import { AppColors } from '../../Utils/helper/AppColors';

const { RangePicker } = DatePicker;

export default function CallRecordings() {
	const company = useSelector((state) => state?.app?.company);
	const [loading, setLoading] = useState(false);
	const [filterQuery, setFilterQuery] = useState(null);
	const [recordings, setRecordings] = useState([]);
	const [campaigns, setCampaigns] = useState([]);
	const [dateFilter, setDateFilter] = useState(null);

	const getRecordings = async () => {
		setLoading(true);
		try {
			let res = await CultService.getCallRecordings({
				params: {
					companyId: company?._id,
					users: filterQuery?.users?.map((i) => i._id),
					campaigns: filterQuery?.campaigns?.map((i) => i._id),
				},
			});
			setRecordings(res?.data?.data?.recordings);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const handleSelectCampaign = (i, option) => {
		setFilterQuery((prev) => ({ ...prev, campaigns: [option?.item] }));
	};

	const getCampaings = async () => {
		try {
			let res = await CultService.getCampaignList({ params: { companyId: company?._id } });
			if (res.status == 200) {
				setCampaigns(res?.data);
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
		}
	};

	const handleChange = (e, date) => {
		if (!e) {
			return setDateFilter(null);
		}
		let startDate = moment(new Date(e[0])).format();
		let endDate = moment(new Date(e[1])).format();
		setDateFilter({ ...dateFilter, startDate, endDate });
	};

	const filterArrayByDate = (array, dateFilter) => {
		if (!dateFilter || !dateFilter.startDate || !dateFilter.endDate) {
			return array;
		}
		const { startDate, endDate } = dateFilter;
		return array?.filter((item) => {
			const itemDate = new Date(item.call.createdAt);
			const start = new Date(startDate);
			const end = new Date(endDate);
			return itemDate >= start && itemDate <= end;
		});
	};

	useEffect(() => {
		if (company) {
			getCampaings();
			if (filterQuery?.campaigns?.length > 0) {
				getRecordings();
			}
		}
	}, [company, filterQuery]);

	const getScoreColor = (score) => {
		let res = AppColors.STATUS_GREY;
		if (score < 50) {
			res = AppColors.STATUS_RED;
		}
		if (score >= 40 && score <= 70) {
			res = AppColors.STATUS_YELLOW;
		}
		if (score > 70) {
			res = AppColors.STATUS_GREEN;
		}
		return res;
	};

	return (
		<Stack>
			{loading && <PageLoader />}
			<PageHeading title="Conversations" subTitle="Listen to and manage conversations" />

			<Stack mt={2} gap={2}>
				<Stack direction="row" gap={2}>
					<SelectCampaign handleSelectCampaign={handleSelectCampaign} />
					<RecordingsFilter campaigns={campaigns} setFilterQuery={setFilterQuery} />

					<Stack direction="row" alignItems="center" gap={1} ml="auto">
						<Stack direction="row" alignItems="center" gap={1}>
							<DotIcon size={16} color={getScoreColor(30)} />
							<Typography variant="subtitle2" fontSize={12}>
								Urgent
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="center" gap={1}>
							<DotIcon size={16} color={getScoreColor(50)} />
							<Typography variant="subtitle2" fontSize={12}>
								Needs Review
							</Typography>
						</Stack>
						<Stack direction="row" alignItems="center" gap={1}>
							<DotIcon size={16} color={getScoreColor(80)} />
							<Typography variant="subtitle2" fontSize={12}>
								Stable
							</Typography>
						</Stack>
					</Stack>
					{/* <RangePicker onChange={handleChange} style={{ marginLeft: 'auto' }} /> */}
				</Stack>
				{filterQuery?.campaigns?.length > 0 ? (
					<CallRecordingsList
						fetch={getRecordings}
						height="calc(100vh - 164px)"
						recordings={filterArrayByDate(recordings, dateFilter)}
					/>
				) : (
					<CampaignEmptyState text="Select a campaign to view conversations." />
				)}
			</Stack>
		</Stack>
	);
}

export const PATH_NAME = {
	ROOT: '/',
	PLAYGROUND: '/playground',
	ERROR_404: '/404',
	ERROR_403: '/403',
	LOGIN: '/login',
	SIGNUP: '/signup',
	DASHBOARD: '/dashboard',
	HOME: '/home',
	VERIFICATION_LINK: '/verification/link',
	FORGET_PASSWORD: '/password/forget',
	ACTIONABLE_INSIGHTS: '/actionable/insights',
	REPORTING_INSIGHTS: '/reporting/insights',
	MOOD_INSIGHTS: '/mood/insights',
	CAMPAIGN_INSIGHTS: '/campaign/insights',
	REPORTING_SENTIMENTS: '/reporting/sentiments',
	ISSUES_RAISED: '/issues/raised',
	EMPLOYEES_LIST: '/employees/list',
	EMPLOYEE_ADD: '/employee/add',
	EMPLOYEE_EDIT: '/employee/edit',
	EMPLOYEE_DETAILS: '/employee/:employeeId',
	CRITICAL_RISK: '/risks',
	CAMPAIGN_LIST: '/campaign/list',
	CAMPAIGN_DETAILS: '/campaign/:id',
	CAMPAIGN_EDIT: '/campaign/edit',
	LEADING_TURNOVER: '/leading/turnover',
	INTEGRATION: '/integration',
	FEEDBACK_CALL: '/feedback/call',
	CONVERSATIONS: '/conversations',
	SETTINGS: '/settings',
	CAMPAIGN_SCHEDULING: '/campaign-scheduling',
};

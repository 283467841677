import { Button, Divider, Drawer, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FilterSelectionContainer from '../../../Components/FilterSelectionContainer';
import { MdFilterList } from 'react-icons/md';
import { Checkbox, Tag } from 'antd';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../../Utils/helper/HelperTexts';
import CultService from '../../../Services/CultService';

export default function RecordingsFilter({ setFilterQuery, campaigns }) {
	const [showFilter, setShowFilter] = useState(false);
	const [filters, setFilters] = useState({});
	const [selected, setSelected] = useState({});
	const company = useSelector((state) => state?.app?.company);

	const getUsers = async () => {
		try {
			let res = await Promise.race([CultService.getEmployeesList({ params: { companyId: company?._id } })]);
			if (res.status) {
				setFilters((prev) => ({ ...prev, users: res.data }));
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
		}
	};

	const handleSelectFilter = (category, value) => {
		setSelected((prevFilters) => {
			const categoryValues = prevFilters[category] || [];
			if (categoryValues.includes(value)) {
				const updatedCategoryValues = categoryValues.filter((item) => item !== value);
				if (updatedCategoryValues.length === 0) {
					const { [category]: removed, ...rest } = prevFilters;
					return rest;
				}

				return {
					...prevFilters,
					[category]: updatedCategoryValues,
				};
			} else {
				return {
					...prevFilters,
					[category]: [...categoryValues, value],
				};
			}
		});
	};

	const handleApplyChanges = () => {
		setFilterQuery(selected);
		setShowFilter(false);
	};

	useEffect(() => {
		if (company) {
			getUsers();
		}
	}, [company]);

	useEffect(() => {
		if (campaigns?.length > 0) {
			setFilters((prev) => ({ ...prev, campaigns }));
		}
	}, [campaigns]);

	return (
		<>
			<Button onClick={(e) => setShowFilter(true)} variant="outlined" color="secondary" startIcon={<MdFilterList />}>
				Filters
			</Button>
			<Drawer anchor="right" sx={{ zIndex: 9999 }} open={showFilter} onClose={() => setShowFilter(false)}>
				<FilterSelectionContainer
					title="conversations"
					onClose={() => setShowFilter(false)}
					handleApplyChanges={handleApplyChanges}
				>
					<Stack gap={2} divider={<Divider />}>
						{Object?.keys(filters || {})?.map((filter, fIndex) => (
							<Stack gap={2} key={fIndex}>
								<Typography textTransform="capitalize" variant="subtitle1">
									{filter}
								</Typography>

								{filters?.[filter]?.map((item, iIndex) => (
									<Stack key={iIndex} direction="row" alignItems="center" gap={1}>
										<Checkbox
											checked={selected[filter]?.includes(item) || false}
											onChange={() => handleSelectFilter(filter, item)}
										/>
										<Tag style={{ borderRadius: 50, margin: 0, textTransform: 'capitalize' }} color="blue">
											{item?.basicDetails?.name || item.firstName + ' ' + item?.lastName}
										</Tag>
									</Stack>
								))}
							</Stack>
						))}
					</Stack>
				</FilterSelectionContainer>
			</Drawer>
		</>
	);
}

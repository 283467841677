import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PATH_NAME } from './Pathname';
import Login from '../Screens/Auth/Login';
import AuthGuard from '../Guards/AuthGuard';
import Dashboard from '../Screens/Dashboard/Dashboard';
import SignUp from '../Screens/Auth/SignUp';
import VerificationLink from '../Screens/Auth/VerificationLink';
import ForgetPassword from '../Screens/Auth/ForgetPassword';
import ReportingSentiments from '../Screens/Reporting/ReportingSentiments';
import ReportingContextInsights from '../Screens/Reporting/ReportingContextInsights';
import IssuesRaised from '../Screens/Issues/IssuesRaised';
import EmployeesList from '../Screens/Employees/EmployeesList';
import AddEditEmployee from '../Screens/Employees/AddEditEmployee';
import CampaignList from '../Screens/Campaign/CampaignList';
import EditCampaign from '../Screens/Campaign/EditCampaign';
import LeadingTurnover from '../Screens/LeadingTurnover/LeadingTurnover';
import Integration from '../Screens/Integration/Integration';
import FeedbackCall from '../Screens/Feedback/FeedbackCall';
import CallRecordings from '../Screens/Recordings/CallRecordings';
import CampaignDetails from '../Screens/Campaign/CampaignDetails';
import CriticalRisks from '../Screens/CriticalRisks/CriticalRisks';
import Settings from '../Screens/Settings/Settings';
import SchedulingCampaignExternal from '../Screens/Campaign/Components/SchedulingCampaignExternal';
import { SIGN_UP_FLOW } from '../Utils/helper/AppConstants';
import EmployeeDetailsContainer from '../Screens/Employees/EmployeeDetailsContainer';
import MoodInsights from '../Screens/Reporting/MoodInsights';
import CampaignInsights from '../Screens/Reporting/CampaignInsights';
import ActionableInsights from '../Screens/Reporting/ActionableInsights';

export default function AppRoutes() {
	return (
		<Suspense fallback={<div />}>
			<React.Fragment>
				<Routes>
					{/* external routes */}
					<Route path={PATH_NAME.FEEDBACK_CALL} element={<FeedbackCall />} />
					<Route path={PATH_NAME.ROOT} element={<Navigate to={PATH_NAME.DASHBOARD} />} />
					<Route path={PATH_NAME.LOGIN} element={<Login />} />
					{SIGN_UP_FLOW && (
						<>
							<Route path={PATH_NAME.SIGNUP} element={<SignUp />} />
							<Route path={PATH_NAME.VERIFICATION_LINK} element={<VerificationLink />} />
							<Route path={PATH_NAME.FORGET_PASSWORD} element={<ForgetPassword />} />
						</>
					)}
					<Route path={PATH_NAME.CAMPAIGN_SCHEDULING} element={<SchedulingCampaignExternal />} />

					<Route element={<AuthGuard />}>
						<Route path={PATH_NAME.DASHBOARD} element={<Dashboard />} />
						<Route path={PATH_NAME.ACTIONABLE_INSIGHTS} element={<ActionableInsights />} />
						<Route path={PATH_NAME.REPORTING_INSIGHTS} element={<ReportingContextInsights />} />
						<Route path={PATH_NAME.MOOD_INSIGHTS} element={<MoodInsights />} />
						<Route path={PATH_NAME.CAMPAIGN_INSIGHTS} element={<CampaignInsights />} />
						<Route path={PATH_NAME.REPORTING_SENTIMENTS} element={<ReportingSentiments />} />

						<Route path={PATH_NAME.ISSUES_RAISED} element={<IssuesRaised />} />
						<Route path={PATH_NAME.EMPLOYEES_LIST} element={<EmployeesList />} />
						<Route path={PATH_NAME.EMPLOYEE_ADD} element={<AddEditEmployee />} />
						<Route path={PATH_NAME.EMPLOYEE_EDIT} element={<AddEditEmployee />} />
						<Route path={PATH_NAME.EMPLOYEE_DETAILS} element={<EmployeeDetailsContainer />} />
						<Route path={PATH_NAME.CRITICAL_RISK} element={<CriticalRisks />} />
						<Route path={PATH_NAME.CAMPAIGN_LIST} element={<CampaignList />} />
						<Route path={PATH_NAME.CAMPAIGN_DETAILS} element={<CampaignDetails />} />
						<Route path={PATH_NAME.CAMPAIGN_EDIT} element={<EditCampaign />} />
						<Route path={PATH_NAME.LEADING_TURNOVER} element={<LeadingTurnover />} />
						<Route path={PATH_NAME.INTEGRATION} element={<Integration />} />
						<Route path={PATH_NAME.CONVERSATIONS} element={<CallRecordings />} />
						<Route path={PATH_NAME.SETTINGS} element={<Settings />} />
					</Route>
				</Routes>
			</React.Fragment>
		</Suspense>
	);
}

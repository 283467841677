import { Divider, InputLabel, Stack, Typography } from '@mui/material';
import React from 'react';

export default function EmployeeDetails({ details }) {
	return (
		<Stack
			sx={{
				'& .MuiInputLabel-root': { width: 200 },
				'& .MuiDivider-root': { my: 2 },
				'& .MuiStack-root': { flexDirection: 'row', alignItems: 'center' },
			}}
		>
			<Stack>
				<InputLabel>Name</InputLabel>
				<Typography variant="subtitle1">
					{details.firstName} {details?.lastName}
				</Typography>
			</Stack>

			<Divider />

			<Stack>
				<InputLabel>Email address</InputLabel>
				<Typography variant="subtitle1">{details?.email}</Typography>
			</Stack>

			<Divider />

			<Stack>
				<InputLabel>Role</InputLabel>
				<Typography variant="subtitle1">{details?.role}</Typography>
			</Stack>

			<Divider />

			<Stack>
				<InputLabel>Department</InputLabel>
				<Typography variant="subtitle1">{details?.department}</Typography>
			</Stack>

			<Divider />

			<Stack>
				<InputLabel>Gender</InputLabel>
				<Typography variant="subtitle1">{details?.gender}</Typography>
			</Stack>

			<Divider />

			<Stack>
				<InputLabel>Phone Number</InputLabel>
				<Typography variant="subtitle1">{details?.phoneNumber}</Typography>
			</Stack>

			<Divider />

			<Stack>
				<InputLabel>Time Zone</InputLabel>
				<Typography variant="subtitle1">{details?.timezone}</Typography>
			</Stack>

			<Divider />

			<Stack>
				<InputLabel>Country</InputLabel>
				<Typography variant="subtitle1">{details?.country}</Typography>
			</Stack>
		</Stack>
	);
}

import { Avatar, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { AppColors } from '../Utils/helper/AppColors';
import { useLocation, useNavigate } from 'react-router-dom';
import { setNavbarExpanded } from '../store/reducers/appActions';
import { useDispatch } from 'react-redux';
import { mainOptions } from './mainOptions';
import toast from 'react-hot-toast';
import { HelperTexts } from '../Utils/helper/HelperTexts';
import { RocketIcon } from '../Components/Icons/Icons';

export default function Navlist() {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const navigateToPage = (option) => {
		if (option.upcoming) return toast(HelperTexts.COMING_SOON, { icon: <RocketIcon color={AppColors.TEXT_RED} /> });
		dispatch(setNavbarExpanded(false));
		navigate(option?.to);
	};

	return (
		<Stack
			height="100%"
			overflow="auto"
			gap={0.5}
			sx={{
				'&::-webkit-scrollbar': {
					display: 'none',
					scrollbarWidth: 'none',
				},
			}}
		>
			{mainOptions(pathname)?.map((option, index) => (
				<Stack mt={option.label === 'Support' && 'auto'} key={index}>
					<Stack
						onClick={() => navigateToPage(option)}
						sx={{ cursor: 'pointer', transition: 'all .2s', ':hover': { bgcolor: AppColors.BACKGROUND_PRIMARY } }}
						height={40}
						borderRadius="6px"
						px={1}
						alignItems="center"
						gap={1}
						direction="row"
						bgcolor={option.isSelected ? AppColors.BACKGROUND_PRIMARY : AppColors.WHITE}
					>
						<Avatar sx={{ height: 20, width: 20 }} src={option.icon} />
						<Typography variant="subtitle1" fontWeight={600} color={AppColors.TEXT_SECONDARY}>
							{option?.label}
						</Typography>
					</Stack>

					<Stack
						height={option?.subOptions?.length > 0 && option?.isSelected ? 'fit-content' : 0}
						overflow="hidden"
						ml={4}
						borderLeft="1px solid"
						borderColor={AppColors.BORDER_GREY}
					>
						{option?.subOptions?.map((subOption) => (
							<Link
								underline="none"
								key={subOption?.label}
								onClick={() => navigateToPage(subOption)}
								p={1}
								px={1.5}
								fontSize={12}
								fontWeight={500}
								color={subOption?.isSelected ? AppColors.TEXT_PRIMARY : AppColors.DARK_GREY}
							>
								{subOption?.label}
							</Link>
						))}
					</Stack>
				</Stack>
			))}
		</Stack>
	);
}

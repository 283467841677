import {
	Button,
	Card,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React from 'react';
import { AppColors } from '../../../Utils/helper/AppColors';
import { CloseIcon } from '../../../Components/Icons/Icons';
import { MdArrowForward } from 'react-icons/md';
import UserThumbnail from '../../../Components/User/UserThumbnail';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: AppColors.WHITE,
	borderRadius: 3,
};

export default function KeyIssueUsers({ item, onClose }) {
	return (
		<Stack width={{ xs: '80vw', md: '60vw' }} p={2} sx={style} gap={2}>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography variant="subtitle1">{item?.name}</Typography>
				<IconButton onClick={onClose} size="small">
					<CloseIcon />
				</IconButton>
			</Stack>

			<Card noShadow>
				<Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
					<Typography variant="subtitle2" width="50%">
						Sample Text
					</Typography>

					<Button endIcon={<MdArrowForward />} variant="contained" color="info">
						Schedule a solution discovery call
					</Button>
				</Stack>
			</Card>

			<TableContainer sx={{ maxHeight: 'calc(100vh - 260px)' }}>
				<Table stickyHeader>
					<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
						<TableCell>Name</TableCell>
						<TableCell>Issue</TableCell>
					</TableHead>
					<TableBody>
						{item?.mentions?.map((c, index) => (
							<TableRow key={index}>
								<TableCell>
									<UserThumbnail userId={c?.userId?._id} user={c.userId} />
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2">{c?.text}</Typography>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Stack>
	);
}

import React, { useEffect, useState } from 'react';
import CultService from '../Services/CultService';
import { useSelector } from 'react-redux';
import { HelperTexts } from '../Utils/helper/HelperTexts';
import toast from 'react-hot-toast';
import { Select } from 'antd';

export default function SelectCampaign({ handleSelectCampaign }) {
	const company = useSelector((state) => state?.app?.company);
	const [campaigns, setCampaigns] = useState([]);

	const getCampaings = async () => {
		try {
			let res = await CultService.getCampaignList({ params: { companyId: company?._id } });
			if (res.status == 200) {
				setCampaigns(res?.data);
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
		}
	};

	useEffect(() => {
		if (company) {
			getCampaings();
		}
	}, [company]);

	return (
		<Select
			onChange={handleSelectCampaign}
			style={{ width: '50%' }}
			showSearch
			placeholder="Select a Campaign"
			filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
		>
			{campaigns.map((option) => (
				<Select.Option key={option._id} value={option._id} item={option}>
					{option?.basicDetails?.name}
				</Select.Option>
			))}
		</Select>
	);
}

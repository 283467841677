import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AppColors } from '../../../Utils/helper/AppColors';
import { MdOutlinePlayCircleOutline } from 'react-icons/md';
import moment from 'moment';
import PlayingModal from './PlayingModal';
import { DotIcon } from '../../../Components/Icons/Icons';
import CultService from '../../../Services/CultService';
import { useSelector } from 'react-redux';
import UserThumbnail from '../../../Components/User/UserThumbnail';
import NotesColumn from './NotesColumn';

export default function CallRecordingsList({ recordings, height, fetch, hideUser }) {
	const [selected, setSelected] = useState(null);
	const user = useSelector((state) => state.app.user);

	const getScoreColor = (score) => {
		let res = AppColors.STATUS_GREY;
		if (score < 40) {
			res = AppColors.STATUS_RED;
		}
		if (score >= 40 && score <= 70) {
			res = AppColors.STATUS_YELLOW;
		}
		if (score > 70) {
			res = AppColors.STATUS_GREEN;
		}
		return res;
	};

	const handleAddComment = async ({ payload, onComplete }) => {
		payload = { ...payload, userId: user?._id };
		let res = await CultService.addComment({ data: payload });
		if (res.status == 200) {
			fetch();
			if (typeof onComplete == 'function') {
				onComplete();
			}
		}
	};

	return (
		<>
			<TableContainer sx={{ minHeight: height, maxHeight: height }}>
				<Table stickyHeader>
					<TableHead
						sx={{
							'& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 },
						}}
					>
						{!hideUser && <TableCell width={180}>Employee</TableCell>}
						<TableCell width="30%">Summary</TableCell>
						<TableCell />
						{!hideUser && <TableCell>Department</TableCell>}
						<TableCell width={120}>Date</TableCell>
						<TableCell>Recording</TableCell>
						<TableCell>Notes</TableCell>
					</TableHead>
					<TableBody>
						{recordings?.map((c, index) => (
							<TableRow sx={{ '& .MuiTableCell-root': { verticalAlign: 'top' } }}>
								{!hideUser && (
									<TableCell>
										<UserThumbnail user={{ firstName: c.name }} />
									</TableCell>
								)}
								<TableCell>
									<Typography variant="subtitle2">{c?.callSummary?.summary}</Typography>
								</TableCell>

								<TableCell>
									<DotIcon size={18} color={getScoreColor(c.callSummary?.score)} />
								</TableCell>

								{!hideUser && (
									<TableCell>
										<Typography sx={{ textTransform: 'capitalize' }} variant="subtitle2">
											{c?.department?.toLowerCase()}
										</Typography>
									</TableCell>
								)}

								<TableCell>
									<Typography variant="subtitle2" fontSize={12}>
										{moment(c?.call?.startedAt).format('MMM DD, YY')}
									</Typography>
								</TableCell>

								<TableCell>
									<Stack direction="row" alignItems="center" gap={0.5}>
										<MdOutlinePlayCircleOutline
											cursor="pointer"
											onClick={() => setSelected(c)}
											color={AppColors.PRIMARY_DARK}
											size={32}
										/>
										<Typography variant="subtitle1" fontSize={12}>
											Recording {index + 1}
										</Typography>
									</Stack>
								</TableCell>

								<TableCell>
									<NotesColumn id={c._id} handleAddComment={handleAddComment} comments={c?.comments} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<PlayingModal open={Boolean(selected)} onClose={() => setSelected(null)} selected={selected} />
		</>
	);
}

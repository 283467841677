import React from 'react';
import StepperHeading from '../StepperHeading';
import { Card, Grid, InputLabel, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import { AppColors } from '../../../../Utils/helper/AppColors';

export default function CampaignPreview({ details, viewOnly }) {
	return (
		<>
			{!viewOnly && <StepperHeading title="Campaign Preview" subTitle="Please preview the campaign before publishing" />}
			<Card sx={{ overflow: 'scroll', height: 'calc(100vh - 178px)' }}>
				<Grid sx={{ '& .MuiStack-root': { gap: 0.5, maxWidth: 'fit-content' } }} container spacing={2}>
					<Grid item xs={6}>
						<Stack>
							<InputLabel>Name</InputLabel>
							<Typography variant="subtitle2">{details?.basicDetails?.name || '--'}</Typography>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<Stack>
							<InputLabel>Description</InputLabel>
							<Typography variant="subtitle2">{details?.basicDetails?.description || '--'}</Typography>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<Stack>
							<InputLabel>Start Date</InputLabel>
							<Typography variant="subtitle2">
								{details?.scheduling?.startDate ? moment(details?.scheduling?.startDate).format('ll') : '--'}
							</Typography>
						</Stack>
					</Grid>

					<Grid item xs={6}>
						<Stack>
							<InputLabel>End Date</InputLabel>
							<Typography variant="subtitle2">
								{details?.scheduling?.endDate ? moment(details?.scheduling?.endDate).format('ll') : '--'}
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<Stack>
							<InputLabel>Start Time</InputLabel>
							<Typography variant="subtitle2">
								{details?.scheduling?.startTime ? moment(details?.scheduling?.startTime, 'HH:mm').format('LT') : '--'}
							</Typography>
						</Stack>
					</Grid>

					<Grid item xs={6}>
						<Stack>
							<InputLabel>End Time</InputLabel>
							<Typography variant="subtitle2">
								{details?.scheduling?.endTime ? moment(details?.scheduling?.endTime, 'HH:mm').format('LT') : '--'}
							</Typography>
						</Stack>
					</Grid>

					<Grid item xs={6}>
						<Stack>
							<InputLabel>Max tries in a campaign</InputLabel>
							<Typography variant="subtitle2">{details?.scheduling?.maxTry || '--'}</Typography>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<Stack>
							<InputLabel>Max tries in a day</InputLabel>
							<Typography variant="subtitle2">{details?.scheduling?.maxTryPerDay || '--'}</Typography>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<Stack>
							<InputLabel>Auto call</InputLabel>
							<Typography variant="subtitle2">
								{details?.defaultSchedule ? (
									<IoIosCheckmarkCircle color={AppColors.TEXT_SUCCESS} size={20} />
								) : (
									<IoIosCloseCircle color={AppColors.DARK_GREY} size={20} />
								)}
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<Stack>
							<InputLabel>Total Users</InputLabel>
							<Typography variant="subtitle2">{details?.users?.length || 0}</Typography>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<Stack>
							<InputLabel>Email body</InputLabel>
							<p dangerouslySetInnerHTML={{ __html: details?.email?.body }} />
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<Stack>
							<InputLabel>Email signature</InputLabel>
							<p dangerouslySetInnerHTML={{ __html: details?.email?.signature }} />
						</Stack>
					</Grid>
				</Grid>
			</Card>
		</>
	);
}

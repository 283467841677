import { Button, Card, Divider, Grid, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { InfoIcon } from '../../../Components/Icons/Icons';
import { AppColors } from '../../../Utils/helper/AppColors';
import { useSelector } from 'react-redux';
import CultService from '../../../Services/CultService';
import { HelperTexts } from '../../../Utils/helper/HelperTexts';
import toast from 'react-hot-toast';
import PageLoader from '../../../Components/PageLoader';
import KeyIssueUsers from './KeyIssueUsers';

export default function KeyIssues() {
	const [loading, setLoading] = useState(false);
	const company = useSelector((state) => state?.app?.company);
	const [details, setDetails] = useState(null);
	const [selected, setSelected] = useState(null);

	const getDetails = async () => {
		setLoading(true);
		try {
			let res = await CultService.getActionalbleInsigts({ params: { companyId: company?._id } });
			setDetails(res?.data?.data);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (company) getDetails();
	}, [company]);

	const list = details?.map((item) => ({
		name: item.name,
		reportedBy: item.reportedBy,
		mentions: item.subTopic.filter((sub) => sub.sentiment === 'Positive').flatMap((sub) => sub.mentions),
	}));

	return (
		<Stack>
			{loading && <PageLoader />}
			<Grid container spacing={3}>
				{list?.map((item) => (
					<Grid item xs={12} sm={6} md={4} xl={3}>
						<Card sx={{ p: 0, height: '100%' }}>
							<Typography p={1.5} textAlign="center" m="auto" variant="subtitle1">
								{item?.name}
							</Typography>
							<Divider sx={{ width: '100%' }} />
							<Stack width="100%" alignItems="center" justifyContent="center" p={3}>
								<Typography mb={3} textAlign="center" variant="subtitle2">
									Sample Text
								</Typography>

								<Typography variant="subtitle2">Reported By</Typography>
								<Typography variant="subtitle1">
									<span style={{ fontWeight: 600, fontSize: 16 }}>{item?.reportedBy}</span> Users
								</Typography>
							</Stack>
							<Divider sx={{ width: '100%' }} />
							<Stack width="100%" p={1} alignItems="center" justifyContent="center">
								<Button
									onClick={() => setSelected(item)}
									endIcon={<InfoIcon color={AppColors.PRIMARY_LIGHT} />}
									size="small"
									variant="text"
								>
									Show Users
								</Button>
							</Stack>
						</Card>
					</Grid>
				))}
			</Grid>

			<Modal open={Boolean(selected)} onClose={() => setSelected(null)}>
				<KeyIssueUsers item={selected} onClose={() => setSelected(null)} />
			</Modal>
		</Stack>
	);
}

import React, { useEffect, useState } from 'react';
import CallRecordingsList from '../../Recordings/Components/CallRecordingsList';
import CultService from '../../../Services/CultService';
import { HelperTexts } from '../../../Utils/helper/HelperTexts';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import PageLoader from '../../../Components/PageLoader';
import { useParams } from 'react-router-dom';

export default function EmpoyeeConversations() {
	const [recordings, setRecordings] = useState([]);
	const [loading, setLoading] = useState(false);
	const company = useSelector((state) => state?.app?.company);
	const { employeeId } = useParams();

	const getRecordings = async () => {
		setLoading(true);
		try {
			let res = await CultService.getCallRecordings({ params: { companyId: company?._id, users: [employeeId] } });
			setRecordings(res?.data?.data?.recordings);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (company) {
			getRecordings();
		}
	}, [company]);

	return (
		<>
			{loading && <PageLoader />}
			<CallRecordingsList height="calc(100vh - 156px)" recordings={recordings} />
		</>
	);
}

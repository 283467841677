import { PATH_NAME } from '../Routes/Pathname';
import dashboardIcon from '../Assets/Icons/Navbar/dashboardIcon.svg';
import reportingIcon from '../Assets/Icons/Navbar/reportingIcon.svg';
import employeesIcon from '../Assets/Icons/Navbar/employeesIcon.svg';
import campaignIcon from '../Assets/Icons/Navbar/campaignIcon.svg';
import issuesIcon from '../Assets/Icons/Navbar/issuesIcon.svg';
import orgIcon from '../Assets/Icons/Navbar/orgIcon.svg';
import integrationIcon from '../Assets/Icons/Navbar/integrationIcon.svg';
import recordingIcon from '../Assets/Icons/Navbar/recordingIcon.svg';
import supportIcon from '../Assets/Icons/Navbar/supportIcon.svg';
import settingsIcon from '../Assets/Icons/Navbar/settingsIcon.svg';
import { matchPath } from 'react-router-dom';

export const mainOptions = (pathname) => [
	{
		label: 'Dashboard',
		to: PATH_NAME.DASHBOARD,
		icon: dashboardIcon,
		isSelected: matchPath({ path: PATH_NAME?.DASHBOARD }, pathname),
	},
	{
		label: 'Insights',
		to: PATH_NAME.MOOD_INSIGHTS,
		icon: reportingIcon,
		isSelected:
			matchPath({ path: PATH_NAME?.ACTIONABLE_INSIGHTS }, pathname) ||
			matchPath({ path: PATH_NAME?.REPORTING_INSIGHTS }, pathname) ||
			matchPath({ path: PATH_NAME?.MOOD_INSIGHTS }, pathname) ||
			matchPath({ path: PATH_NAME?.CAMPAIGN_INSIGHTS }, pathname),
		subOptions: [
			{
				label: 'Mood',
				to: PATH_NAME.MOOD_INSIGHTS,
				isSelected: matchPath({ path: PATH_NAME?.MOOD_INSIGHTS }, pathname),
			},
			{
				label: 'Actionable insights',
				to: PATH_NAME.ACTIONABLE_INSIGHTS,
				isSelected: matchPath({ path: PATH_NAME?.ACTIONABLE_INSIGHTS }, pathname),
			},
			{
				label: 'Campaign insights',
				to: PATH_NAME.CAMPAIGN_INSIGHTS,
				isSelected: matchPath({ path: PATH_NAME?.CAMPAIGN_INSIGHTS }, pathname),
			},
		],
	},
	// {
	// 	label: 'Leading turnover',
	// 	to: PATH_NAME.LEADING_TURNOVER,
	// 	icon: leadingIcon,
	// 	isSelected: matchPath({ path: PATH_NAME.LEADING_TURNOVER }, pathname),
	// },
	{
		label: 'Employees',
		to: PATH_NAME.EMPLOYEES_LIST,
		icon: employeesIcon,
		isSelected:
			matchPath({ path: PATH_NAME.EMPLOYEES_LIST }, pathname) ||
			matchPath({ path: PATH_NAME.EMPLOYEE_ADD }, pathname) ||
			matchPath({ path: PATH_NAME.EMPLOYEE_EDIT }, pathname),
	},
	{
		label: 'Campaign',
		to: PATH_NAME.CAMPAIGN_LIST,
		icon: campaignIcon,
		isSelected: matchPath({ path: PATH_NAME.CAMPAIGN_LIST }, pathname) || matchPath({ path: PATH_NAME.CAMPAIGN_EDIT }, pathname),
	},
	{
		label: 'Issues',
		to: PATH_NAME.ISSUES_RAISED,
		icon: issuesIcon,
		isSelected: matchPath({ path: PATH_NAME.ISSUES_RAISED }, pathname),
	},
	// {
	// 	label: 'Critical alerts',
	// 	to: PATH_NAME.CRITICAL_RISK,
	// 	icon: orgIcon,
	// 	isSelected: matchPath({ path: PATH_NAME.CRITICAL_RISK }, pathname),
	// },
	{
		label: 'Integration',
		to: PATH_NAME.INTEGRATION,
		icon: integrationIcon,
		isSelected: matchPath({ path: PATH_NAME.INTEGRATION }, pathname),
	},
	{
		label: 'Conversations',
		to: PATH_NAME.CONVERSATIONS,
		icon: recordingIcon,
		isSelected: matchPath({ path: PATH_NAME.CONVERSATIONS }, pathname),
	},
	{
		label: 'Support',
		to: PATH_NAME.DASHBOARD,
		icon: supportIcon,
		upcoming: true,
		isSelected: matchPath({ path: '' }, pathname),
	},
	{
		label: 'Settings',
		to: PATH_NAME.SETTINGS,
		icon: settingsIcon,
		isSelected: matchPath({ path: PATH_NAME.SETTINGS }, pathname),
	},
];

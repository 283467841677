import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import { AppColors } from '../../Utils/helper/AppColors';
import MentionProgress from './Components/MentionProgress';
import CultService from '../../Services/CultService';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import PageLoader from '../../Components/PageLoader';
import { useSelector } from 'react-redux';
import { extractNumberFromString } from '../../Utils/helper/commonUtils';

export default function ActionableInsights() {
	const [loading, setLoading] = useState(false);
	const company = useSelector((state) => state?.app?.company);
	const [details, setDetails] = useState(null);

	const getDetails = async () => {
		setLoading(true);
		try {
			let res = await CultService.getActionalbleInsigts({ params: { companyId: company?._id } });
			setDetails(res?.data?.data);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const mentions = details
		?.flatMap((topic) => topic?.subTopic.flatMap((subtopic) => subtopic?.mentions))
		?.sort((a, b) => extractNumberFromString(b?.reported || 0) - extractNumberFromString(a?.reported || 0));

	useEffect(() => {
		if (company) getDetails();
	}, [company]);

	return (
		<Stack gap={3}>
			{loading && <PageLoader />}
			<PageHeading title="Actionable Insights" subTitle="Insights into profound employee dynamics" />

			<TableContainer sx={{ height: 'calc(100vh - 120px)' }}>
				<Table stickyHeader>
					<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
						<TableCell>Insights</TableCell>
						<TableCell>Mentions</TableCell>
						<TableCell>Recommended Action</TableCell>
					</TableHead>

					<TableBody>
						{mentions?.map((mention) => (
							<TableRow>
								<TableCell>
									<Typography variant="subtitle2">{mention?.text}</Typography>
								</TableCell>
								<TableCell>
									<Typography>
										<MentionProgress hidePercentageSign value={Math.round(mention?.reported?.replace('%', ''))} />
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2">{mention?.solution}</Typography>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Stack>
	);
}
